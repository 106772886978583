@media only screen and (max-width: 600px) {
  .gallery-img-container {
    height: 50vh;
    overflow: hidden;
    text-align: center;
    align-items: center;

    border-radius: 25px;
  }
}

.border-none {
  border: none;
}

.gallery-img {
  display: inline-block;
  height: 50vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 15px;

  border-radius: 25px;

  /* width: 100%; */
}

.gallery-img:hover {
  opacity: 0.7;
  cursor: pointer;
}

.img-title {
  text-align: center;
}

/* The Modal (background) */
.modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 2; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.9); /* Black w/ opacity */
}

/* Modal Content (image) */
.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}

/* Caption of Modal Image */
.modal-caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}

/* Add Animation */
.modal-content,
.modal-caption {
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
  }
}

@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

/* The Close Button */
.close {
  position: absolute;
  top: 75px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

.scroll-top-btn {
  display: block;
  position: fixed;
  background-color: #757575;
  left: 20px;
  bottom: 20px;
  /* border-radius: 50%; */

  border: none;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  /* background-color: pink; */
  font-size: larger;
  /* text-align: center; */
}

.scroll-top-btn:hover {
  cursor: pointer;
  background-color: #ccc;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
  .modal-content {
    margin-top: 40px;
    width: 100%;
  }
}
