.sophia-logo {
  width: 500px;
  max-width: 100%;
}
.btn-bridal {
  background-color: #ce7d98;

  border: none;
  padding: 10px;
}
.btn-bridal:hover {
  background-color: #a5647a;
}
.btn-bridal:active {
  background-color: #8b5567 !important;
}

.btn-header {
  margin-top: 50px;
}

.alterations-bg {
  background-color: #ce7d98;
}
.home-container {
  height: 100vh;
  text-align: center;
}
/* Laptop screens:  */

@media only screen and (min-width: 600px) {
}
