.footer-container {
  width: 100% !important;

  height: 40vh;
  text-align: center;
  background-color: #f8f9fa !important;
}
.footer-wrapper {
  padding-top: 20px;
}

.footer-text {
  font-weight: 100;
  font-size: small;
  text-align: center;
}

.footer-links {
  text-align: right;
}
.footer-link {
  text-decoration: none;
  color: darkgray;
  font-size: small;
  margin-bottom: 0 !important;
  padding: 0 !important;
}

.powered-text {
  margin-bottom: 50px;
}

.footer-links-title {
  margin: 0;
}

.footer-company-name {
  display: inline;
}

.footer-company-icon {
  display: inline;
  width: 20px;
  margin-right: 5px;
}

.footer-icons {
  text-align: center;
  padding-top: 2vh;
  font-size: x-large;
}

.footer-icon {
  padding-left: 10px;
  padding-right: 10px;
  color: #ffffff;

  padding-bottom: 10px;
}

.footer-icon:hover {
  color: lightblue;
}

.social {
  padding: 20px;
  font-size: 30px;
  width: 50px;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
  color: #ce7d98;

  /* font-size: 100px; */
}

.social:hover {
  text-decoration: none;
  color: #a3657a;
}
@media only screen and (max-width: 600px) {
  .footer-container {
    height: 40vh;
  }
}
